import React from "react";
import { Container, Button, Image, Text } from "@atoms";
import clsx from "clsx";

const LearningTracks = ({ tracks, button, link }) => {
  return (
    <Container variant="sm" className="mt-10 sm:mt-16">
      {/* learning tracks */}
      <ul className="flex flex-col items-start gap-8 sm:flex-row">
        {tracks?.map(({ image, heading, copy, popular }) => {
          return (
            <li
              className={clsx(
                "w-full items-start bg-white p-4 sm:w-1/2 md:w-1/3",
                {
                  "rounded-xl shadow-flat": popular,
                  "rounded-lg shadow-flat/15": !popular,
                }
              )}
            >
              {image && (
                <span className="relative block aspect-[315/236] overflow-hidden rounded-2xl">
                  <Image image={image} fill />
                </span>
              )}
              <span className="my-3 inline-block w-full">
                {popular && (
                  <span className="float-right rounded bg-purple p-1 text-xs font-bold text-white">
                    Most Popular
                  </span>
                )}
                <Text variant="h6">{heading}</Text>
              </span>
              <Text
                variant="xs"
                className="prose-list-checkmark prose font-medium"
              >
                {copy}
              </Text>
            </li>
          );
        })}
      </ul>

      {/* buttons */}
      {(button?.url || link?.url) && (
        <div className="mt-10 flex flex-wrap items-center justify-center gap-5">
          {button?.url && (
            <Button to={button?.url} color="purple" size="md" outline>
              {button?.text}
            </Button>
          )}

          {link?.url && (
            <Button to={link?.url} size="md" color="white" outline>
              {link?.text}
            </Button>
          )}
        </div>
      )}
    </Container>
  );
};

export default LearningTracks;
